import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { ReactComponent as FeatureIcon1 } from 'assets/images/feature-tile-icon-01.svg'
import { ReactComponent as FeatureIcon2 } from 'assets/images/feature-tile-icon-02.svg'
import { ReactComponent as FeatureIcon3 } from 'assets/images/feature-tile-icon-03.svg'
import { ReactComponent as FeatureIcon4 } from 'assets/images/feature-tile-icon-04.svg'
import { ReactComponent as FeatureIcon5 } from 'assets/images/feature-tile-icon-05.svg'
import { ReactComponent as FeatureIcon6 } from 'assets/images/feature-tile-icon-06.svg'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'What is DUCKTOPIA?',
    paragraph: 'DUCKTOPIA is a collection of 10,000 unique 3D well-designed ducks united together to get on the Ethereum Blockchain each ducktopia is unique and exclusive based on a hundred traits. The objective is to build the strongest community and project around NFTs.'
  };

  const FeatureTilesHeader = {
    title: 'FEATURES OF DUCKTOPIA',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <SectionHeader data={FeatureTilesHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon1 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    INCREASE THE VALUE OF YOUR NFT
                  </h4>
                  <p className="m-0 text-sm">
                    If you are lucky and mint one of our rare NFT you can 2x, 5x, maybe even 10x the value of your investment within a few days.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon2 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    HOLDERS REWARDS
                  </h4>
                  <p className="m-0 text-sm">
                    The holders will get access to our community funds used to ​​invest, reward, and collaborate with the best projects in the space and projects ideas of our members.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon3 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    ACCESS TO PRIVATE EVENTS
                  </h4>
                  <p className="m-0 text-sm">
                    A big party will be organised to reward all of our holders and to celebrate the achievement of our goals. Bali, LA, Dubai ? The community choose
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon4 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    OUR DAO
                  </h4>
                  <p className="m-0 text-sm">
                    They will be able to wear their 3D Bored Bunny and join Bored Bunny City, a Private Land on the Metaverse where only our community can have access.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon5 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    BREED YOUR NFT
                  </h4>
                  <p className="m-0 text-sm">
                    Bored Bunny owners can breed their Bunnies to create a 2nd Generation Bunny. Just keep in mind, you'll need a total of 2 Bored Bunny to breed, so hop to it
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FeatureIcon6 />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    MORE TO COME
                  </h4>
                  <p className="m-0 text-sm">
                    Various events and airdrops are introduced in ROADMAP.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;