export const types = {
  SET_MINT_COUNT: 'set_mint_count'
}

export const actions = {
  setMintCount: (count) => ({ type: types.SET_MINT_COUNT, payload: count }),
}

const initialState = {
  count: 1
}

export const mintReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MINT_COUNT:
      return {
        ...state,
        count: action.payload
      }
    default:
      return state;
  }
}