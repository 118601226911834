import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'redux/mint'

const Counter = ({ min, max }) => {
  const dispatch = useDispatch()
  const [count, setCount] = useState(1)
  const { setMintCount } = actions;

  const handleDecrease = () => {
    if (count <= min) return;
    setCount(count - 1)
    dispatch(setMintCount(count - 1))
  }

  const handleIncrease = () => {
    if (count >= max) return;
    setCount(count + 1)
    dispatch(setMintCount(count + 1))
  }

  return (
    <>
      <div className='mint-counter'>
        <div className='mint-operator' onClick={handleDecrease}>
          <span>-</span>
        </div>
        <div>
          <input
            min={min}
            max={max}
            disabled
            className='form-input'
            type='number'
            value={count}
            onChange={(e) => setCount(e.target.value)}
          />
        </div>
        <div className='mint-operator' onClick={handleIncrease}>
          <span>+</span>
        </div>
      </div>
    </>
  )
}

export default Counter