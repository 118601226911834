import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useContractCall, useContractFunction } from '@usedapp/core';
import { GenesisDuckAddress } from 'contracts';
import ABI from 'abi/GenesisDuck.json';

const contractInterface = new ethers.utils.Interface(ABI)
const GenesisDuck = new Contract(
  GenesisDuckAddress,
  contractInterface
);

// getters
export const useNumberMinted = (owner) => {
  const [numberMinted] =
    useContractCall(
      owner && {
        abi: contractInterface,
        address: GenesisDuckAddress,
        method: 'numberMinted',
        args: [owner],
      }
    ) ?? [];
  return numberMinted ? formatUnits(numberMinted, 0) : 0;
};

export const useTotalSupply = () => {
  const [totalSupply] =
    useContractCall(
      {
        abi: contractInterface,
        address: GenesisDuckAddress,
        method: 'totalSupply',
        args: [],
      }
    ) ?? [];
  return totalSupply ? formatUnits(totalSupply, 0) : 0;
};

export const useCurrentPhase = () => {
  const [phase] =
    useContractCall(
      {
        abi: contractInterface,
        address: GenesisDuckAddress,
        method: 'currentSalePhase',
        args: [],
      }
    ) ?? [];
  return phase ? formatUnits(phase, 0) : undefined;
};

export const useAuctionPrice = () => {
  const [price] =
    useContractCall(
      {
        abi: contractInterface,
        address: GenesisDuckAddress,
        method: 'getAuctionPrice',
        args: [],
      }
    ) ?? [];
  return price ? parseFloat(formatUnits(price, 'ether')) : undefined;
}

export const usePublicSalePrice = () => {
  const [price] =
    useContractCall(
      {
        abi: contractInterface,
        address: GenesisDuckAddress,
        method: 'publicSalePrice',
        args: [],
      }
    ) ?? [];
  return price ? parseFloat(formatUnits(price, 'ether')) : undefined;
}

// setters
export const useDevMint = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'mintForCCBuyers', {});
  return { state, send, event };
};

export const useStartAuction = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'startAuctionSale', {});
  return { state, send, event };
};

export const useStartPublicSale = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'startPublicSale', {});
  return { state, send, event };
};

export const useAuctionMint = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'auctionSaleMint', {});
  return { state, send, event };
};

export const usePublicSaleMint = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'publicSaleMint', {});
  return { state, send, event };
};

export const useSetBaseURI = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'setBaseURI', {});
  return { state, send, event };
};

export const useWithdrawETH = () => {
  const { state, send, event } = useContractFunction(GenesisDuck, 'withdrawMoney', {});
  return { state, send, event };
}