import React, { useRef, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux'
import { useLocation, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Mainnet, Rinkeby, DAppProvider } from '@usedapp/core';
import { buildStore } from 'redux/store'
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import 'react-toastify/dist/ReactToastify.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import MintPage from 'views/Mint';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const config = {
  readOnlyChainId: Rinkeby.chainId,
  readOnlyUrls: {
    [Rinkeby.chainId]: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  },
};

const store = buildStore()

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <ReduxProvider store={store}>
          <DAppProvider config={config}>
            <Routes>
              <Route exact path="/" element={<MintPage />} />
            </Routes>
            <ToastContainer
              position='top-center'
              autoClose={5000}
              autoDismiss={true}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              icon={true}
              theme={'colored'}
              pauseOnHover={false}
              rtl={false}
            />
          </DAppProvider>
        </ReduxProvider>
      )} />
  );
}

export default App;