import { createStore, compose, applyMiddleware } from 'redux'
import createRootReducer from './rootReducer'

const identity = (v) => v

const getDevTools = () => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof window === 'object' && window.devToolsExtension) {
      return window.devToolsExtension()
    }
    return identity
  }
  return identity
}

export const buildStore = (reduxState = undefined) => {
  const store = createStore(
    createRootReducer(),
    reduxState,
    compose(getDevTools())
  )

  return store
}

