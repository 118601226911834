import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import LayoutDefault from 'layouts/LayoutDefault'
import Counter from 'components/mint/counter'
import Button from 'components/elements/Button'
import SectionHeader from 'components/sections/partials/SectionHeader'
import DuckLogo from 'assets/images/logo.png'
import { toast } from 'react-toastify'
import { parseEther } from '@ethersproject/units'
import { useEthers } from '@usedapp/core'
import {
  useNumberMinted,
  useTotalSupply,
  useCurrentPhase,
  useAuctionPrice,
  usePublicSalePrice,
  useAuctionMint,
  usePublicSaleMint,
} from 'hooks/mintHook'

const MintPage = () => {
  /// state
  const mintCount = useSelector((state) => state.mint.count)
  const [mintPrice, setMintPrice] = useState(0)
  const [mintStatus, setMintStatus] = useState({
    title: 'MINT YOUR OWN DUCK!',
    paragraph: 'Not started yet...'
  })

  /// hooks
  const { account } = useEthers();
  const totalSupply = useTotalSupply()
  const numberMinted = useNumberMinted(account)
  const phase = useCurrentPhase()
  const auctionPrice = useAuctionPrice()
  const publicSalePrice = usePublicSalePrice()
  const { state: auctionMintState, send: auctionMint } = useAuctionMint()
  const { state: publicMintState, send: publicMint } = usePublicSaleMint()

  /// UI handlers
  const handleMint = async () => {
    console.log(mintCount)
    const value = parseEther((mintCount * mintPrice)?.toString());
    if (phase === '1') {
      await auctionMint(mintCount, { value })
    } else if (phase === '2') {
      await publicMint(mintCount, { value })
    } else {
      toast.warn("Mint not started yet");
    }
  }

  const handleCCMint = () => {
    window.location.href = 'https://ducktopia.com/cc'
  }

  /// useEffect hooks
  useEffect(() => {
    switch (phase) {
      case '0':
        setMintPrice(0)
        setMintStatus({ ...mintStatus, paragraph: 'Mint not initiated yet...' })
        break
      case '1':
        setMintPrice(auctionPrice)
        setMintStatus({ ...mintStatus, paragraph: 'AUCTION MINTING LIVE' })
        break
      case '2':
        setMintStatus({ ...mintStatus, paragraph: 'PUBLIC MINTING LIVE' })
        setMintPrice(publicSalePrice)
        break
      default:
    }
  }, [phase])

  useEffect(() => {
    auctionMintState.errorMessage && toast.error(auctionMintState.errorMessage)
    publicMintState.errorMessage && toast.error(publicMintState.errorMessage)
  }, [auctionMintState, publicMintState])

  return (
    <LayoutDefault>
      <form className='mint-form reveal-from-bottom' data-reveal-delay="200">
        <SectionHeader data={mintStatus} className='center-content' />
        <div>
          <img src={DuckLogo} alt="logo" />
        </div>
        <div>{`Current Mint Price: ${mintPrice.toFixed(3)} ETH`}</div>
        <Counter min={1} max={5} />
        <div className='pt-2'>{`${numberMinted} owned`}</div>
        {
          totalSupply === '10000' ?
            (
              <div className='mt-2'>SOLD OUT</div>
            ) :
            (
              <div className='mt-2'>{`${totalSupply} / 10,000 availble`}</div>
            )
        }
        <div className='mint-btn-group'>
          <Button type='button' color={'primary'} onClick={handleMint}>MINT with ETH</Button>
          <Button type='button' onClick={handleCCMint}>MINT with CC</Button>
        </div>
        {/* <CircleTimer /> */}
        {
          (phase === '1') && <div className="elfsight-app-3af951ea-9c9f-44cc-abf0-286788450814"></div>
        }
      </form>
    </LayoutDefault>
  )
}

export default MintPage
